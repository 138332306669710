<template>
  <div id="sign-up" class="uk-height-viewport">
    <div class="uk-flex uk-flex-center">
        <div style="width:480px">

            <div style="height:200px">
                <img class="uk-margin-large-top" src="@/assets/IE-Logo.png" height="220" width="448" alt="CMS3" />
            </div>

            <div class="uk-card-body uk-box-shadow-xlarge custom-card-bg">
                <h3 class="uk-text-lead">{{$t('Pages.SignUp.Title')}}</h3>

                <form id="sign-up-form" @submit.prevent="onSubmit();" @reset.prevent="onReset();"> 
                    <div class="uk-margin-medium">

                        <div class="uk-inline uk-width-1-1">
                            <span class="uk-form-icon" uk-icon="icon: user"></span>
                            <input type="text" 
                            :class="this.validity.firstName.valid === false ? 'uk-input invalid-border': 'uk-input'" 
                            :placeholder="$t( 'Generic.Labels.FirstName' )" v-model.trim="form.firstName"
                            @keydown.enter.exact.prevent="onSubmit()"
                            @blur="checkFieldOnBlur('firstName')" @keyup="validateName('firstName')">
                            <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="this.validity.firstName.valid === false" uk-icon="icon: warning" ></span>
                            <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="this.validity.firstName.valid === false" > {{validity.firstName.message}}</span>
                        </div>

                        <div class="uk-margin">
                            <div class="uk-inline">
                                <span class="uk-form-icon" uk-icon="icon: user"></span>
                                <input
                                    :class="this.validity.lastName.valid === false ? 'uk-input uk-form-width-large invalid-border': 'uk-input uk-form-width-large'"
                                    type="text"
                                    :placeholder="$t( 'Generic.Labels.LastName' )"
                                    v-model.trim="form.lastName"
                                    autocomplete="off"
                                    @keyup="validateName('lastName')"
                                    @blur="checkFieldOnBlur('lastName')"
                                    @keydown.enter.exact.prevent="onSubmit()"
                                    required
                                />
                                <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="this.validity.lastName.valid === false" uk-icon="icon: warning"></span>
                                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="this.validity.lastName.valid === false" > {{validity.lastName.message}}</span>
                            </div>
                        </div>
                        
                        <div class="uk-margin">
                            <div class="uk-inline">
                                <span class="uk-form-icon" uk-icon="icon: mail"></span>
                                <input
                                    :class="this.validity.email.valid === false && !emailFocused ? 'uk-input uk-form-width-large invalid-border': 'uk-input uk-form-width-large'"
                                    type="email"
                                    :placeholder="$t( 'Generic.Labels.Email')"
                                    v-model="form.email"
                                    autocomplete="off"
                                    @keyup="validateEmail()"
                                    @keydown.enter.exact.prevent="onSubmit()"
                                    @blur="() => {emailFocused = false; checkFieldOnBlur('email')}"
                                    required
                                    @focus="emailFocused = true"
                                />
                                <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="this.validity.email.valid === false && !emailFocused" uk-icon="icon: warning"></span>
                                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="validity.email.valid === false && !emailFocused" > {{validity.email.message}}</span>
                            </div>
                        </div>

                        <div class="uk-margin">
                            <div class="uk-inline">
                                <span class="uk-form-icon" uk-icon="icon: lock"></span>
                                <input
                                    :class="this.validity.password.valid === false ? 'uk-input uk-form-width-large invalid-border': 'uk-input uk-form-width-large'"
                                    type="password"
                                    :placeholder="$t('Generic.Labels.Password')"
                                    v-model="form.password"
                                    autocomplete="new-password"
                                    @keyup="validatePassword()"
                                    @keydown.enter.exact.prevent="onSubmit()"
                                    @blur="checkFieldOnBlur('password')"
                                    required
                                />
                                <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="this.validity.password.valid === false" uk-icon="icon: warning"></span>
                                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="validity.password.valid === false" > {{validity.password.message}}</span>
                            </div>
                        </div>

                        <div class="uk-margin">
                            <div class="uk-inline">
                                <span class="uk-form-icon" uk-icon="icon: lock"></span>
                                <input
                                    :class="!confirmPasswordFocused && this.validity.confirmPassword.valid === false ? 'uk-input invalid-border uk-form-width-large': 'uk-input uk-form-width-large'"
                                    type="password"
                                    :placeholder="$t( 'Generic.Labels.ConfirmPassword')"
                                    v-model="form.confirmPassword"
                                    autocomplete="new-password"
                                    @keyup="validateConfirmPassword()"
                                    @keydown.enter.exact.prevent="onSubmit()"
                                    @blur="() => {confirmPasswordFocused = false; checkFieldOnBlur('confirmPassword') }"
                                    @focus="confirmPasswordFocused = true"
                                    required
                                />
                                <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!confirmPasswordFocused && this.validity.confirmPassword.valid === false" uk-icon="icon: warning"></span>
                                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!confirmPasswordFocused && validity.confirmPassword.valid === false" > {{validity.confirmPassword.message}}</span>
                            </div>
                        </div>
                    </div>

                </form>


                <div class="uk-margin">
                    <button class="uk-button uk-button-default uk-margin-small-right uk-width-small" @click.prevent="goBack()">{{$t('Actions.Cancel')}}</button>
                    <input v-if="!loading" class="uk-button uk-button-primary uk-width-small" type="submit" @click.prevent="onSubmit()" :value="$t('Pages.SignUp.SignUp')" />
                    <button v-else class="uk-button uk-button-primary uk-width-small" type="button" :disabled="loading"><div uk-spinner="ratio: 0.5"></div></button>
                </div>
            </div>

        </div>
    </div>
  </div>
</template>

<script>
import Notification from '@/components/util/Notification';

export default {
    name: 'SignUp',
    data () {
        return {
            loading: false,
            format: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/,
            emailFocused: false,
            confirmPasswordFocused: false,
            form: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: ""
            },
            validity: {
                firstName: {},
                lastName: {},
                email: {},
                password: {},
                confirmPassword: {}
            }
        }
    },
    created () {
        this.setDefaultValue()
    },
    methods: {
        async onSubmit () {
            this.validateConfirmPassword()
            const validationResponse = this.validateForm()
            if (validationResponse !== true) {
                return false
            }
            this.loading = true
            const { firstName, lastName, email, password } = this.form
            const response = await this.$store.dispatch('registerCMSUser', { firstName, lastName, email, password });
            this.loading = false
            if (response?.user?.email) {
                this.$router.push({ name: "Login" });
                this.setDefaultValue()
                return Notification.showNotification( this.$t('Pages.SignUp.SignUpSuccessful'), this.$t('Pages.SignUp.SignUpSuccessfulMessage'));
            } else {
                return Notification.showNotification(this.$t('Pages.SignUp.SignUpUnsuccessful'), response, response?.includes('Server Error') ? 'error' : 'warn')
            }
        },
        validateForm() {
            let isValid = true
            for (const key in this.form) {
                if (!this.form[key].length) {
                    this.validity[key].valid = false
                    this.validity[key].message = this.$t('Generic.Errors.MandatoryField')
                    isValid = false
                } else if (!this.validity[key].valid) {
                    isValid = false
                    return this.validity[key].message
                } 
            }
            return isValid
        },
        goBack () {
            this.setDefaultValue()
            this.$router.go(-1)
        },
        setDefaultValue () {
            for (const key in this.validity) {
                this.validity[key] = {
                    valid: null,
                    message: ''
                }
                this.form[key] = ''
            }
        },
        validateName (field) {
            // this.form[field] = this.form[field]?.trim()
            if (this.form[field].length) {
                if (this.form[field].length > 2) {
                    if (this.format.test(this.form[field])) {
                        this.validity[field].valid = false
                        this.validity[field].message = this.$t('Generic.Errors.SpecialCharsNotAllowed')
                    } else {
                        this.validity[field].valid = true
                        this.validity[field].message = ''
                    }
                } else {
                    this.validity[field].valid = false
                    this.validity[field].message = this.$t('Generic.Errors.Min3Chars')
                }
            } else {
                if (this.validity[field].valid === false)
                    return false
                this.validity[field].valid = null
                this.validity[field].message = ''
            }
        },
        validateEmail () {
            this.form.email = this.form.email?.trim()
            if (!this.form.email.length) {
                if (this.validity.email.valid === false)
                    return false
                this.validity.email.valid = ''
                return false
            }
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(String(this.form.email).toLowerCase())) {
                this.validity.email.valid = true
                this.validity.email.message = ''
            } else {
                this.validity.email.valid = false
                this.validity.email.message = this.$t('Generic.Errors.InvalidEmail')
            }
        },
        validatePassword () {
            this.form.password = this.form.password?.trim()
            if (this.form.password.length) {
                if (this.form.password.length < 10) {
                  this.validity.password.valid = false,
                  this.validity.password.message = this.$t('Generic.Errors.Min10Chars')
                  return false
                } else {
                  this.validity.password.valid = true,
                  this.validity.password.message = ''
                }
            } else {
                if (this.validity.password.valid === false)
                    return false
                this.validity.password.valid = null
                this.validity.password.message = ''
            }
        },
        validatePasswords () {
            if (!this.form.confirmPassword.length) {
                this.validity.confirmPassword.valid = false
                this.validity.confirmPassword.message = this.$t('Generic.Errors.Min10Chars')
            }
        },
        validateConfirmPassword () {
            this.confirmPasswordFocused = false;
            if (!this.form.confirmPassword.length) {
                if (this.validity.confirmPassword.valid === false)
                    return false
                this.validity.confirmPassword.valid = null
            } else if (this.form.confirmPassword !== this.form.password) {
                this.validity.confirmPassword.valid = false
                this.validity.confirmPassword.message = this.$t('Generic.Errors.PasswordsShouldMatch')
            } else {
                this.validity.confirmPassword.valid = true
                this.validity.confirmPassword.message = ''
            }
        },
        checkFieldOnBlur (field) {
            if (!this.form[field].length) {
                this.validity[field].valid = false
                this.validity[field].message = this.$t('Generic.Errors.MandatoryField')
            }
        }
    }
}
</script>

<style scoped>
 .uk-inline .valid-icon {
    color: #55A800;
}
.uk-button-primary:disabled {
  background: var(--app-background-color) !important;
  pointer-events: none;
}
.custom-card-bg {
  background: var(--app-secondary-color);
}

</style>